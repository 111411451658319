.messages-wrapper {


  .MuiAlert-root {
    margin: 0 0 10px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}