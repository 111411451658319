.country-match {

    .table-wrapper {
        width:100%;
    }

    &__search-actions {
        display: flex;
        margin: 10px 0 10px 0;
        div {
            margin-right: 20px;
        }
    }

    &__selection-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 50px;
        width: 100%;
    }

    &__title {
     margin-bottom: 34px;
    }

    &__btn-action-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
        flex-direction: column;
    }
    .country-item {
        cursor: pointer;

        &:hover {
            background: blanchedalmond;
        }

        &--selected {
            background: #c5ff87;
            &:hover {
                background: #c5ff87;
            }
        }
    }
    .paper {
        width: 100%;
        padding: 20px;
        min-height: 500px;
        margin-bottom: 2;
      }
}