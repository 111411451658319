*,
*:after,
*:before {
  padding: 0;
  margin: 0
}

html {
  height: 100%;
  width: 100%;
  box-sizing: inherit;
  font-size: 14px;
}

body {
  height: 100%;
  box-sizing: border-box;
}

#root {
  height: 100%;
  width: 100%;
}
