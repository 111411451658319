$login-background-color: #f2f4f7;
$color-solid-white: #ffffff;
$border-header-form: #70bbfd;
$default-title-color: #646777;
$color-light-grey: #999999;
$MBA-color: #70bbfd;


// Border Radius
$border-radius-default: 4px;
$border-radius-form: 8px;


// Box Shadows
$login-card-box-shadow: 0 1.5rem 4rem rgba(#000000, 0.15);
