.status-indicator {
  // margin: 0 auto;
  width: 120px;
  display: block;
  padding: 4px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-radius: 16px;
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 11px;
  &--info {
    color: rgb(13, 60, 97);
    background-color: rgb(232, 244, 253);
  }

  &--success {
    color: rgb(30, 70, 32);
    background-color: rgb(237, 247, 237);
  }

  &--error {
    color: rgb(97, 26, 21);
    background-color: rgb(253, 236, 234);
  }

  &--init {
    color: rgb(0, 0, 0);
    background-color: rgb(224, 224, 224);
  }

  &--declined {
    color: rgb(255, 255, 255);
    background-color: rgb(244, 67, 54);
  }

  &--warning {
    color: rgb(102, 60, 0);
    background-color: rgb(255, 244, 229);
  }
}