.no-axes-presenter {
  min-width: 350px;
  // height: 190px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  margin: 20px;
  display: flex;
 
  // flex-direction: column;
  // justify-content: space-between;

  &__total-amount {
    font-size: 25px;
    font-weight: 800;
    display: flex;
    justify-content: space-between;

    svg {
      color: #d8dfe9;
    }
  }

  &__total-amount-right {
    font-size: 25px;
    font-weight: 800;
    display: flex;
    justify-content: flex-end;

    svg {
      color: #d8dfe9;
    }
  }
  &__total-amount-label {
    color: #646777;
    font-size: 16px;
  }

}

.row-no-axes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.column-no-axes {
  // display: flex;
  // flex-direction: column;
  // flex: 50%;
  // justify-content: flex-end;
  // flex: 1;
  align-items: center; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  //   justify-content: center;
}