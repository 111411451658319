@import '../../assets/styles/abstracts/variables';

.user-initials {
  border-radius: 50%;
  background-color: $color-light-grey;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 3.3em;

  &__letter {
    color: $color-solid-white;
  }
}
