.percentage-presenter {
  // min-width: 280px;
  min-width: 350px;
  // height: 160px;
  // padding: 20px;
  padding: 21.5px;
  background: white;
  border-radius: 8px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__total-amount {
    font-size: 25px;
    font-weight: 800;
    display: flex;
    justify-content: space-between;

    svg {
      color: #d8dfe9;
    }
  }

  &__total-amount-label {
    color: #646777;
    font-size: 16px;
  }

  &__progress-section {
    display: flex;
    flex-direction: column;
    justify-content: right;
  }

  &__percentage {
    font-size: 14px;
    font-weight: 800;
    text-align: right;
  }

  .progress {
    &__outer {
      width: 100%;
      height: 14px;
      border-radius: 10px;
      box-shadow: none;
      background-color: #ddd;
    }

    &__inner {
      // display: inline-block;
      background: transparent;
      width: 0;
      height: 13px;

      border-radius: 9px;
    }
  }
}