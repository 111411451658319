@import '../assets/styles/abstracts/variables';
@import '../assets/styles/abstracts/mixins';

.main-app-container {
  height: 100%;
  width: 100%;
  padding-top: 64px;
  transition: all 0.2s ease-in-out;
  background-color: $login-background-color;
  overflow-y: auto;

  @include respond(phone) {
    padding-top: 56px;
  }

  &.shrink {
    margin-left: 250px;
    width: calc(100% - 250px);
  }

  &.expanded {
    padding-left: 80px;

    @include respond(phone) {
      padding-left: 57px;
    }
  }
}
