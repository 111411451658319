.company-form {
  height: 100%;

  &__form {
    position: relative;
    max-width: 1400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: '50px';
    width: '100%';
    background: white;

    &-raw {
      text-align: center;
      width: 100%;
      margin-bottom: 20px;

      .MuiTextField-root {
        width: 100%;
      }

      &.btn-container {
        text-align: left;
        margin-bottom: 0;
      }

      textarea.notes {
        width: 100%;
        padding: 5px;
        border-radius: 4px;
        resize: none;
        max-height: 200px;
        overflow-y: auto;
      }

      &.user-type {
        label {
          width: 120px;
        }
      }
    }
  }
}