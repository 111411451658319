.dashboard {
  height: 100%;
  width: 100%;
  // padding: 2rem;
  &__total-amount-container {
    // display: flex;
    transition: all 0.5s ease;
    // justify-content: flex-start;
    // flex-wrap: wrap;
    // direction: row;
    // align-items: flex-start;
    // padding-right: 20px;
    // padding-left: 20px;
    padding-right: 22px;
    padding-left: 16px;
  }
}
