.order-items-body span.error{
    color: #f44336;
}

.order-items-total-cost-preview {
    min-width: 100px;
    padding: 10px 20px;
    font-weight: bold;
    background: khaki;
    border-radius: 12px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    text-align: center;

}
.total-item-cost--error {
    border: 1px solid red;
    padding: 5px 10px;
    border-radius: 4px;
    color: red;
}