@import '../../assets/styles/abstracts/variables';

.login-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $login-background-color;

  &__form-wrapper {
    background-color: $color-solid-white;
    box-shadow: $login-card-box-shadow;
    padding: 70px 100px;
    border-radius: $border-radius-form;
    width: 600px;
  }

  &__header {
    margin-bottom: 40px;
    padding-left: 10px;
    border-left: 4px solid $border-header-form;
  }

  &__default-title {
    font-size: 24px;
    color: $default-title-color;
    margin-right: 8px;
  }

  &__default-title-bold {
    font-size: 24px;
    margin-right: 8px;
    font-weight: 700;
    color: $default-title-color;
  }

  &__default-title-blue-bold {
    font-size: 24px;
    font-weight: 700;
    color: $MBA-color;
  }

  &__small-title {
    font-size: 12px;
    color: $color-light-grey;
    line-height: 16px;
  }

  &__form {
    background-color: $color-solid-white;
    text-align: center;
  }

  &__submit-button-container {
    margin: 20px 0;

    button {
      width: 150px;
    }
  }

  &__submit-button {
    margin: 10px 0;
    width: 100%;
  }
}
