.vessel_stats_table {
  // width: 400px;
  // height: 140px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
}