.dashboard {
  height: 100%;
  width: 100%;
  padding: 2rem;
  &__total-amount-container {
    display: flex;
    transition: all 0.5s ease;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
